<div class="container py-5">
  <div class="title pb-5">
    <app-separator></app-separator>
    <h2>{{ locale.services.title }}</h2>
    <p>
      {{ locale.services.desc }}
    </p>
  </div>
  <div [swiper]="config" [performance] class="swiper-container row">
    <div class="swiper-wrapper">
      <div
        class="card col-lg-3 col-md-5 col-sm-6 swiper-slide"
        *ngFor="let item of locale.services.items"
        [routerLink]="'servicios/' + item.id"
      >
        <img
          class="card-img"
          [defaultImage]="'assets/services/morelia-low.jpg'"
          [lazyLoad]="item.image"
        />
        <div class="card-img-overlay">
          <div class="card-brand">
            <app-brand></app-brand>
          </div>
          <div class="card-overlay-body">
            <h4 class="card-title">{{ item.shortTitle }}</h4>
            <span class="badge badge-secondary">24 hrs</span>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination-service"></div>
  </div>
</div>
