<section>
  <div class="container py-5">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" width="50"></th>
          <th scope="col">{{ locale.title }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of locale.table">
          <th scope="row"><bi name="check"></bi></th>
          <td>{{ item }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
