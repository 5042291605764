import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuadrosoft',
  templateUrl: './cuadrosoft.component.html',
  styleUrls: ['./cuadrosoft.component.scss']
})
export class CuadrosoftComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
